import { useIncidentService } from "~/src/services/IncidentService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { useGetIncidentByIdQuery } = useIncidentService();
  const result = await useGetIncidentByIdQuery(String(to.params.id));
  if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Incident not found",
      })
    );
});
